import React from 'react';
import styles from './index.module.scss'; // Import the corresponding SCSS file for styles

const emailServiceData = {
  title: "About Us",
  mission: {
    title: "Our Mission",
    description: "To provide reliable email validation services that help businesses improve their email deliverability and marketing effectiveness."
  },
  vision: {
    title: "Our Vision",
    description: "To be the leading provider of email verification solutions, constantly innovating to enhance user experience and security. We welcome feedback and inquiries at: +1 877-264-5155 or help@emailservicedata.info."
  },
  moreInfo: {
    title: "More About Us",
    description: "Founded in 2020, our service specializes in comprehensive email validation, helping clients reduce bounce rates and increase engagement."
  },
  image: {
    src: "https://static.tapfiliate.com/5c900d55ca344.jpg?a=46179-ade7e0&s=1135997-0ef4a7", // Update this URL to your actual image
    alt: "Email Validation Service"
  }
};

const AboutUs = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <h1>{emailServiceData.title}</h1>
      <div className={styles.columns}>
        <div className={styles.column}>
          <h2>{emailServiceData.mission.title}</h2>
          <p>{emailServiceData.mission.description}</p>
        </div>
        <div className={styles.column}>
          <h2>{emailServiceData.vision.title}</h2>
          <p>{emailServiceData.vision.description}</p>
        </div>
        <div className={styles.column}>
          <h2>{emailServiceData.moreInfo.title}</h2>
          <p>{emailServiceData.moreInfo.description}</p>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={emailServiceData.image.src}
          alt={emailServiceData.image.alt}
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default AboutUs;
